<template>
  <div
    :class="{
      balloon: is_balloon,
      rakuten: is_rakuten,
      tori: is_tori,
    }"
    v-show="is_ready"
  >
    <div id="wapper">
      <RakutenHeader v-if="is_rakuten" />
      <Header
        :is_balloon="is_balloon"
        :is_rakuten="is_rakuten"
        :is_tori="is_tori"
        :is_show_contact="is_show_contact"
        :unit_name="unit_name"
        :subtitle_first="subtitle_first"
        :subsubtitle="subsubtitle"
      />
      <div id="container">
        <div id="main">
          <!-- <div id="top-banner-ads" class="pc">
            <div class="sponsored_link">[スポンサーリンク]</div>
            <div id="ad_top_1"></div>
          </div> -->
          <!-- <div class="info">
            【1ステージあたりのゲームの障害物が最大10個から最大5個に変更となりました。】
          </div> -->
          <div class="info" v-if="site_name === 'phalanx_saison'">
            【メダル2倍キャンペーン】<br />
            ■期間 : 2024年12月2日(月) 10:30 ～ 2024年12月27日(金) 10:30<br />
            ■内容 : 対象期間中メダルが2倍!（バッジ3個で400メダルに交換）
          </div>
          <div class="info" v-if="site_name === 'moppy'">
            【「トリポップ」（PC・SP）終了のお知らせ】<br /><br />これまでご支援を賜りました「トリポップ」は、<br />2024年4月30日（火）15：00をもちましてサービスを終了することとなりました。<br /><br />サービス終了日<br />■2024年4月30日（火）15：00<br /><br />サービス終了後は、ゲームへの参加ができない状態となりますのであらかじめご了承ください。<br />『 トリポップ 』を長らくご愛顧いただき、誠にありがとうございました。<br />
          </div>
          <div class="info" v-if="site_name === 'netmile_tpoint'">
            【キャンペーン】<br />期間中、ミニコンテンツでもらえるメダルが2倍(2024/12/4(水)10:30～2024/12/27(金)10:30)
          </div>
          <div class="info" v-if="site_name === 'netmile_ponta'">
            【キャンペーン】<br />期間中、ミニコンテンツでもらえるメダルが2倍(2024/12/4(水)10:30～2024/12/27(金)10:30)
          </div>
          <div class="info" v-if="site_name === 'fruitmail'">
            【お知らせ】<br />トリポップゲームは2022年11月30日24:00をもって終了する予定です。<br />終了後貯めた点数はポイントに交換されなくなりますのでご注意ください。
          </div>
          <div id="gamecontainer">
            <SelectItem
              class="game_selectItem"
              :medals="exchanged_point"
              @closed="closeExchange"
              @exchanged="exchanged"
              v-if="is_balloon && is_showing_exchange"
            />
            <div id="splash" class="cover">
              <div id="buttons">
                <div v-if="is_balloon">
                  <div id="buttons_top">
                    <img
                      src="../assets/images/balloon/btn_start.svg"
                      id="start"
                      v-if="can_play"
                    />
                    <img
                      src="../assets/images/balloon/btn_cant_start_until_3.svg"
                      id="start"
                      v-else-if="next_refresh_hour === 3"
                      class="btn_disabled"
                    />
                    <img
                      src="../assets/images/balloon/btn_cant_start_until_15.svg"
                      id="start"
                      v-else-if="next_refresh_hour === 15"
                      class="btn_disabled"
                    />
                  </div>
                  <div id="buttons_bottom">
                    <img
                      src="../assets/images/balloon/btn_check_point.svg"
                      id="showpoint"
                    />
                    <img
                      src="../assets/images/balloon/btn_show_rule.svg"
                      id="showrule"
                      v-scroll-to="'#rule'"
                    />
                  </div>
                </div>
                <div v-if="is_rakuten">
                  <div id="buttons_top">
                    <img
                      src="../assets/images/rakuten/btn_start.svg"
                      id="start"
                      v-if="can_play"
                    />
                    <img
                      src="../assets/images/rakuten/btn_cant_start_until_0.svg"
                      id="start"
                      v-else-if="next_refresh_hour === 0"
                      class="btn_disabled"
                    />
                    <img
                      src="../assets/images/rakuten/btn_cant_start_until_12.svg"
                      id="start"
                      v-else-if="next_refresh_hour === 12"
                      class="btn_disabled"
                    />
                  </div>
                  <div id="buttons_bottom">
                    <img
                      src="../assets/images/rakuten/btn_check_point.svg"
                      id="showpoint"
                    />
                    <img
                      src="../assets/images/rakuten/btn_show_rule.svg"
                      id="showrule"
                      v-scroll-to="'#rule'"
                    />
                  </div>
                </div>
                <div v-if="is_tori">
                  <div id="buttons_top">
                    <div id="start" class="btn btn_primary" :class="disableBtn">
                      {{ start_text }}
                    </div>
                  </div>
                  <div id="buttons_bottom">
                    <div id="showpoint" class="btn btn_primary">
                      <span>得点<span class="pc">を見る</span></span>
                    </div>
                    <button
                      href="#rule"
                      id="showrule"
                      class="btn btn_primary"
                      v-scroll-to="'#rule'"
                    >
                      <span>遊び方<span class="pc">を見る</span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div id="gamescreen">
              <div id="ceiling"></div>
              <div id="sky" class="animated"></div>
              <div id="land">
                <div id="debug"></div>
              </div>
              <div id="flyarea">
                <div id="player" class="bird">
                  <div class="before-bird animated"></div>
                </div>
                <div id="playermessage"></div>
                <div id="startmessage"></div>
                <div id="countdown"></div>
                <div id="message"></div>
                <div id="bigscore"></div>
                <div
                  class="pipe animated"
                  v-for="pipe in pipes"
                  :key="pipe.message"
                >
                  <div
                    class="pipe_upper"
                    :style="{ height: pipe.topheight }"
                  ></div>
                  <div
                    class="pipe_lower"
                    :style="{ height: pipe.bottomheight }"
                  ></div>
                </div>
              </div>
            </div>
            <div id="play_guide_2" class="cover">
              <div class="popup rule-popup">
                <p id="play_guide_title_2" class="popup_title">遊び方</p>
                <div class="img_play_guide">
                  <img
                    src="../assets/images/balloon/howtoplay_2.png"
                    alt="遊び方"
                    v-if="is_balloon"
                  />
                  <img
                    src="../assets/images/rakuten/howtoplay_2.png"
                    alt="遊び方"
                    v-if="is_rakuten"
                  />
                  <img
                    src="../assets/images/tori/howtoplay_2.png"
                    alt="遊び方"
                    v-if="is_tori"
                  />
                </div>
                <div class="desc_play_guide">
                  {{
                    obstacle_name
                  }}の隙間を一回くぐるごとに2点貯まります。<br />
                  {{ player_name }}が{{
                    obstacle_name
                  }}や天井にぶつかったり、落ちたりすると<br />
                  ゲームオーバーになるので、注意してプレイしてくださいね。
                </div>
                <div id="next_play_guide_2" class="btn btn_primary">
                  <span>始める</span>
                </div>
              </div>
            </div>
            <div id="play_guide_1" class="cover">
              <div class="popup rule-popup">
                <p id="play_guide_title_1" class="popup_title">遊び方</p>
                <div class="img_play_guide">
                  <img
                    src="../assets/images/balloon/howtoplay_1.svg"
                    alt="遊び方"
                    v-if="is_balloon"
                  />
                  <img
                    src="../assets/images/rakuten/howtoplay_1.png"
                    alt="遊び方"
                    v-if="is_rakuten"
                  />
                  <img
                    src="../assets/images/tori/howtoplay_1.png"
                    alt="遊び方"
                    v-if="is_tori"
                  />
                </div>
                <div class="desc_play_guide">
                  操作は画面タップのみ。(パソコンの場合はマウスクリックのみ。)<br />
                  {{ player_name }}を{{ action_name }}上下から伸びる{{
                    obstacle_name
                  }}の隙間をくぐらせていくゲームです。<br />
                  ゲームを始めるをクリック後、画面タップ(画面をクリック)するとゲームが始まります。
                </div>
                <div id="next_play_guide_1" class="btn btn_primary">
                  <span>次へ</span>
                </div>
              </div>
            </div>
            <div id="gameover" class="cover">
              <div class="popup">
                <p id="unfortunately" class="popup_title">ゲームオーバー</p>
                <div id="gameover-banner-ads">
                  <div>
                    <div class="sponsored_link">[スポンサーリンク]</div>
                    <div id="ad_gameover_1"></div>
                  </div>
                  <div>
                    <div class="sponsored_link pc">[スポンサーリンク]</div>
                    <div
                      id="ad_gameover_2"
                      class="pc"
                      style="margin-left: 1px"
                    ></div>
                  </div>
                </div>
                <img
                  src="../assets/images/balloon/btn_check_score.svg"
                  id="showscore"
                  v-if="is_balloon"
                />
                <div id="showscore" class="btn btn_primary" v-else>
                  <span>スコア確認</span>
                </div>
              </div>
            </div>
            <div id="scorecheck" class="cover">
              <div class="popup">
                <div id="finalscore" class="popup_title">
                  <span id="score_title">最終スコア </span
                  ><span id="score_value"></span>
                </div>
                <div id="scorecheck-banner-ads">
                  <div>
                    <div class="sponsored_link">[スポンサーリンク]</div>
                    <div id="ad_result_1"></div>
                  </div>
                  <div>
                    <div class="sponsored_link pc">[スポンサーリンク]</div>
                    <div
                      id="ad_result_2"
                      class="pc"
                      style="margin-left: 1px"
                    ></div>
                  </div>
                </div>
                <router-link
                  id="gototop"
                  :to="{
                    name: rotationing_home_name,
                  }"
                  v-if="is_balloon"
                >
                  <img
                    src="../assets/images/balloon/btn_top.svg"
                    v-if="is_balloon"
                  />
                </router-link>
                <router-link
                  id="gototop"
                  class="btn btn_primary"
                  :to="{
                    name: rotationing_home_name,
                  }"
                  v-else
                >
                  <span>TOPへ</span>
                </router-link>
              </div>
            </div>
            <div id="stageclear" class="cover">
              <div class="popup">
                <p id="congratulations" class="popup_title">ステージクリア</p>
                <div id="stageclear-banner-ads">
                  <div>
                    <div class="sponsored_link">[スポンサーリンク]</div>
                    <div id="ad_clear_1"></div>
                  </div>
                  <div>
                    <div class="sponsored_link pc">[スポンサーリンク]</div>
                    <div
                      id="ad_clear_2"
                      class="pc"
                      style="margin-left: 1px"
                    ></div>
                  </div>
                </div>
                <!-- <div id="currentscore"></div> -->
                <img
                  src="../assets/images/balloon/btn_next.svg"
                  id="next"
                  v-if="is_balloon"
                />
                <div id="next" class="btn btn_primary" v-else>
                  <span>次のステージへ</span>
                </div>
              </div>
            </div>
            <div id="pointcheck" class="cover">
              <div class="popup pointcheck-popup">
                <p id="nowpoint" class="popup_title">現在の獲得点数</p>
                <div v-if="is_balloon">
                  <ul
                    id="medals-popup"
                    class="medals"
                    v-if="exhange_medals_unit > 1"
                  >
                    <li
                      class="medal"
                      v-for="index in exhange_medals_unit"
                      :key="index"
                    >
                      <img
                        :id="'medal_' + index + '-popup'"
                        src="../assets/images/balloon/medal.svg"
                        alt="バッジ"
                        v-if="index <= medal_number"
                      />
                      <img
                        :id="'medal_' + index + '-popup'"
                        src="../assets/images/balloon/no_medal.svg"
                        alt="バッジ"
                        v-else
                      />
                    </li>
                  </ul>
                </div>
                <div v-if="is_rakuten">
                  <ul
                    id="medals-popup"
                    class="medals"
                    v-if="exhange_medals_unit > 1"
                  >
                    <li
                      class="medal"
                      v-for="index in exhange_medals_unit"
                      :key="index"
                    >
                      <img
                        :id="'medal_' + index + '-popup'"
                        src="../assets/images/rakuten/medal.svg"
                        alt="バッジ"
                        v-if="index <= medal_number"
                      />
                      <img
                        :id="'medal_' + index + '-popup'"
                        src="../assets/images/rakuten/no_medal.png"
                        alt="バッジ"
                        v-else
                      />
                    </li>
                  </ul>
                </div>
                <div v-if="is_tori">
                  <ul
                    id="medals-popup"
                    class="medals"
                    v-if="exhange_medals_unit > 1"
                  >
                    <li
                      class="medal"
                      v-for="index in exhange_medals_unit"
                      :key="index"
                    >
                      <img
                        :id="'medal_' + index + '-popup'"
                        src="../assets/images/tori/medal.png"
                        alt="バッジ"
                        v-if="index <= medal_number"
                      />
                      <img
                        :id="'medal_' + index + '-popup'"
                        src="../assets/images/tori/no_medal.svg"
                        alt="バッジ"
                        v-else
                      />
                    </li>
                  </ul>
                </div>
                <!-- <p class="medal-desc">
                  メダル5コでxxポイントの◯ポイントと交換!
                </p> -->
                <div class="point_area progress">
                  <progress
                    id="point-progress-popup"
                    class="point-progress"
                    max="100"
                    :value="remaining_point"
                  >
                    {{ remaining_point }}%
                  </progress>
                </div>
                <p class="point-text">
                  現在 {{ remaining_point }}点 貯まっています
                </p>
                <div id="pointcheck_buttons">
                  <div id="closepoint" class="btn btn_primary">
                    <span>閉じる</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="game-bottom-banner-ads">
            <div>
              <div class="sponsored_link pc">[スポンサーリンク]</div>
              <div id="ad_under_1" class="pc" style="margin-right: 12px"></div>
            </div>
            <div>
              <div class="sponsored_link">[スポンサーリンク]</div>
              <div id="ad_under_2"></div>
            </div>
          </div>
          <div id="rule">
            <div id="howtoplay">
              <div class="subsection">
                <h3 class="section-header rule-header">
                  {{ game_name }}の遊び方
                </h3>
                <dl class="list_howtoplay">
                  <dt class="img_howtoplay">
                    <img
                      src="../assets/images/balloon/howtoplay_1.svg"
                      alt="遊び方"
                      v-if="is_balloon"
                    />
                    <img
                      src="../assets/images/rakuten/howtoplay_1.png"
                      alt="遊び方"
                      v-if="is_rakuten"
                    />
                    <img
                      src="../assets/images/tori/howtoplay_1.png"
                      alt="遊び方"
                      v-if="is_tori"
                    />
                  </dt>
                  <dd class="desc_howtoplay">
                    操作は画面タップのみ。(パソコンの場合はマウスクリックのみ。)<br />
                    {{ player_name }}を{{ action_name }}上下から伸びる{{
                      obstacle_name
                    }}の隙間をくぐらせていくゲームです。<br />
                    ゲームを始めるをクリック後、画面タップ(画面をクリック)するとゲームが始まります。
                  </dd>
                </dl>
                <dl class="list_howtoplay">
                  <dt class="img_howtoplay">
                    <img
                      src="../assets/images/balloon/howtoplay_2.png"
                      alt="遊び方"
                      v-if="is_balloon"
                    />
                    <img
                      src="../assets/images/rakuten/howtoplay_2.png"
                      alt="遊び方"
                      v-if="is_rakuten"
                    />
                    <img
                      src="../assets/images/tori/howtoplay_2.png"
                      alt="遊び方"
                      v-if="is_tori"
                    />
                  </dt>
                  <dd class="desc_howtoplay">
                    {{
                      obstacle_name
                    }}の隙間を一回くぐるごとに2点貯まります。<br />
                    {{ player_name }}が{{
                      obstacle_name
                    }}や天井にぶつかったり、落ちたりすると<br />
                    ゲームオーバーになるので、注意してプレイしてくださいね。
                  </dd>
                </dl>
                <div id="howtoplay-middle-banner-ads">
                  <div class="sponsored_link sp">[スポンサーリンク]</div>
                  <div id="ad_under_3" class="sp"></div>
                </div>
                <dl class="list_howtoplay">
                  <dt class="img_howtoplay">
                    <img
                      src="../assets/images/balloon/howtoplay_3.svg"
                      alt="遊び方"
                      v-if="is_balloon"
                    />
                    <img
                      src="../assets/images/rakuten/howtoplay_3.png"
                      alt="遊び方"
                      v-if="is_rakuten"
                    />
                    <img
                      src="../assets/images/tori/howtoplay_3.png"
                      alt="遊び方"
                      v-if="is_tori"
                    />
                  </dt>
                  <dd class="desc_howtoplay">
                    <span v-html="number_of_plays"></span><br />
                    {{ auto_exchange_text }}<br /><br />
                    【注意事項】<br />
                    ・0点の場合はプレイ回数にカウントされません。<br />
                    ・1回のプレイで最大第3ステージまで進めます。<br />
                    ・遊べる回数は{{ refresh_hour_1 }}時と{{
                      refresh_hour_2
                    }}時にリセットされます。<br /><br />
                    ※2021/11/5より一部修正を行いました。<br /><br />
                    【修正内容】：ステージ途中で外部サイトや広告に遷移した場合でも、<br />途中のステージまでの得点は付与され、途中のステージから再開できる。<br />
                    （10分以内にゲームに戻った場合に限る。）<br />
                    （この際、プレイ回数はリセットされなく消化される。）<br /><br />
                    例：第二ステージの途中で離脱した場合、10分以内に戻れば、<br />第二ステージからまた再開できる。<br />戻らなかった場合でも第一ステージまでの得点は加算されている。<br /><br />
                    【追加機能】：<br />
                    ・ゲーム開始時にカウントダウンの表示（3,2,1)<br />
                    ・ゲームスタート時に「タップしてスタート」の文言追加<br />（上記カウントダウン終了後）
                  </dd>
                </dl>
                <dl class="list_howtoplay">
                  <dt class="img_howtoplay">
                    <img
                      src="../assets/images/balloon/howtoplay_4.svg"
                      alt="遊び方"
                      v-if="is_balloon"
                    />
                    <img
                      src="../assets/images/rakuten/howtoplay_4.png"
                      alt="遊び方"
                      v-if="is_rakuten"
                    />
                    <img
                      src="../assets/images/tori/howtoplay_4.png"
                      alt="遊び方"
                      v-if="is_tori"
                    />
                  </dt>
                  <dd class="desc_howtoplay">
                    {{ delay_text }}<br />
                    得点を多く稼いで、たくさん{{
                      unit_name
                    }}をGETしてくださいね！
                  </dd>
                </dl>
                <dl class="list_howtoplay">
                  <dd class="desc_howtoplay">
                    パソコン・スマホの点数は共通になります。
                  </dd>
                </dl>
              </div>
            </div>
            <div id="howtoplay-bottom-banner-ads">
              <div>
                <div class="sponsored_link pc">[スポンサーリンク]</div>
                <div
                  id="ad_under_4"
                  class="pc"
                  style="margin-right: 12px"
                ></div>
              </div>
              <div>
                <div class="sponsored_link">[スポンサーリンク]</div>
                <div id="ad_under_5"></div>
              </div>
            </div>
            <div id="env">
              <div class="subsection">
                <h3 class="section-header rule-header">推奨環境</h3>
                <div id="desc_env" class="desc_env">
                  OS：Windows 10、Mac OS X
                  10.4以降、iOS13以降、Android7.0以降<br /><br />
                  ブラウザ：Safari最新版、Chrome最新版、Edge最新版 <br /><br />
                  その他：JavaScript有効 Cookie有効
                </div>
              </div>
            </div>
            <!-- <div id="bottom-banner-ads" class="pc">
              <div class="sponsored_link">[スポンサーリンク]</div>
              <div id="ad_bottom_1"></div>
            </div> -->
          </div>
        </div>
        <div id="side">
          <div id="side-top-banner-ads" class="pc">
            <div class="sponsored_link">[スポンサーリンク]</div>
            <div id="ad_right_1" style="margin-bottom: 12px"></div>
            <div class="sponsored_link">[スポンサーリンク]</div>
            <div id="ad_right_2"></div>
          </div>
          <div id="score">
            <div class="subsection">
              <h3 class="section-header">現在の獲得点数</h3>
              <div v-if="is_balloon">
                <ul id="medals" class="medals" v-if="exhange_medals_unit > 1">
                  <li
                    class="medal"
                    v-for="index in exhange_medals_unit"
                    :key="index"
                  >
                    <img
                      :id="'medal_' + index"
                      src="../assets/images/balloon/medal.svg"
                      alt="バッジ"
                      v-if="index <= medal_number"
                    />
                    <img
                      :id="'medal_' + index"
                      src="../assets/images/balloon/no_medal.svg"
                      alt="バッジ"
                      v-else
                    />
                  </li>
                </ul>
              </div>
              <div v-if="is_rakuten">
                <ul id="medals" class="medals" v-if="exhange_medals_unit > 1">
                  <li
                    class="medal"
                    v-for="index in exhange_medals_unit"
                    :key="index"
                  >
                    <img
                      :id="'medal_' + index"
                      src="../assets/images/rakuten/medal.svg"
                      alt="バッジ"
                      v-if="index <= medal_number"
                    />
                    <img
                      :id="'medal_' + index"
                      src="../assets/images/rakuten/no_medal.png"
                      alt="バッジ"
                      v-else
                    />
                  </li>
                </ul>
              </div>
              <div v-if="is_tori">
                <ul id="medals" class="medals" v-if="exhange_medals_unit > 1">
                  <li
                    class="medal"
                    v-for="index in exhange_medals_unit"
                    :key="index"
                  >
                    <img
                      :id="'medal_' + index"
                      src="../assets/images/tori/medal.png"
                      alt="バッジ"
                      v-if="index <= medal_number"
                    />
                    <img
                      :id="'medal_' + index"
                      src="../assets/images/tori/no_medal.svg"
                      alt="バッジ"
                      v-else
                    />
                  </li>
                </ul>
              </div>
              <p class="point-desc">
                {{ exchange_points_string }}
              </p>
              <p class="medal-desc">
                {{ exchange_medals_string }}
              </p>
              <div class="point_area progress">
                <progress
                  id="point-progress"
                  max="100"
                  :value="remaining_point"
                >
                  {{ remaining_point }}%
                </progress>
              </div>
              <p class="point-text">
                現在 {{ remaining_point }}点 貯まっています
              </p>
              <button
                class="btn btn_primary history"
                @click="gotoPointHistories"
              >
                獲得履歴を見る
              </button>
              <button
                class="btn btn_primary exchange"
                v-scroll-to="'#gamecontainer'"
                @click="openExchange"
                v-if="!is_auto_exchange"
              >
                交換する
              </button>
            </div>
            <div class="subsection">
              <h3 class="section-header">今日遊べる回数</h3>
              <p id="life">あと {{ life }} 回</p>
              <p class="life-desc">
                {{ refresh_hour_1 }}時と{{
                  refresh_hour_2
                }}時にリセットされます！
              </p>
            </div>
            <div id="side-bottom-banner-ads" class="pc">
              <div class="sponsored_link">[スポンサーリンク]</div>
              <div id="ad_right_3"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        :is_show_company_link="is_show_company_link"
        :is_balloon="is_balloon"
      />
      <div class="boundingbox" id="playerbox"></div>
      <div class="boundingbox" id="pipebox"></div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/parts/Header";
import Footer from "@/views/parts/Footer";
import RakutenHeader from "@/views/parts/RakutenHeader";
import SelectItem from "@/views/parts/SelectItem";
import { isSP, isSPSmall } from "@/assets/javascripts/common";

import Api from "@/lib/api";
import {
  initScreen,
  initCallback,
  afterSetupUser,
  setIsFirstPlay,
  setIsShowPopupImmediately,
  setIsLoadAdWithStartGame,
  setCanPlay,
  setPlayingGameScore,
} from "@/assets/javascripts/main";
import { setSiteName, setNeedsSingleRequest } from "@/assets/javascripts/ad";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    RakutenHeader,
    SelectItem,
  },
  data() {
    return {
      next_refresh_hour: 0,
      refresh_hour_1: 0,
      refresh_hour_2: 0,
      can_mix_device_type: false,
      delay_text: "",
      exchange_rate_from: 0,
      exchange_rate_to: 0,
      exchange_unit: "",
      is_first_play: false,
      life: 0,
      point: 0,
      exchanged_point: 0,
      playing_game_score: 0,
      site_name: "",
      unit_name: "",
      is_showing_exchange: false,
      is_ready: false,
    };
  },
  computed: {
    disableBtn: function () {
      return {
        btn_disabled: !this.can_play,
        btn_small_text: !this.can_play,
      };
    },
    can_play: function () {
      return this.life > 0;
    },
    exhange_medals_unit: function () {
      // バッジ最大数
      return Math.floor(this.exchange_rate_from / 100);
    },
    medal_number: function () {
      // バッジ数
      return Math.floor(this.point / 100);
    },
    remaining_point: function () {
      // 点数
      return this.point - this.medal_number * 100;
    },
    exchange_points_string: function () {
      // 交換説明文
      return this.exhange_medals_unit > 1
        ? "100点貯まるとバッジ1コと交換！"
        : "100点貯まると" +
            this.exchange_rate_to.toString() +
            this.unit_name +
            "と交換！";
    },
    is_rakuten: function () {
      // 楽天か
      return this.site_name === "rakuten";
    },
    is_balloon: function () {
      // バルーンポップか
      return this.site_name === "phalanx_saison";
    },
    is_tori: function () {
      // トリポップか
      return !this.is_balloon && !this.is_rakuten;
    },
    is_show_contact: function () {
      // 問い合わせリンクを表示するか
      return (
        this.site_name === "netmile_dreammail" ||
        this.site_name === "phalanx_saison" ||
        this.site_name === "rakuten"
      );
    },
    is_show_company_link: function () {
      // 運営会社リンクを表示するか
      return this.site_name === "netmile_ponta";
    },
    game_name: function () {
      // ゲーム名
      if (this.is_balloon) {
        return "バルーンポップ";
      } else if (this.is_rakuten) {
        return "進め！幻の海底神殿";
      } else {
        return "トリポップ";
      }
    },
    player_name: function () {
      // プレイヤー名
      if (this.is_balloon) {
        return "バルーン";
      } else if (this.is_rakuten) {
        return "潜水艦";
      } else {
        return "小鳥";
      }
    },
    action_name: function () {
      // 動作名
      if (this.is_balloon) {
        return "飛ばして";
      } else if (this.is_rakuten) {
        return "浮上させて";
      } else {
        return "飛ばして";
      }
    },
    obstacle_name: function () {
      // 障害物名
      if (this.$is_balloon) {
        return "雲";
      } else if (this.is_rakuten) {
        return "柱";
      } else {
        return "ドカン";
      }
    },
    subsubtitle: function () {
      // サブサブタイトル
      if (!this.is_auto_exchange) {
        // 自動交換なし
        return "";
      } else if (this.exhange_medals_unit > 1) {
        // バッジ最大数が2以上の場合
        return (
          "バッジ" +
          this.exhange_medals_unit +
          "コ(" +
          this.exchange_rate_from.toString() +
          "点)獲得で" +
          this.exchange_rate_to.toString() +
          this.unit_name +
          "ゲット！"
        );
      } else {
        return (
          this.exchange_rate_from.toString() +
          "点獲得で" +
          this.exchange_rate_to.toString() +
          this.unit_name +
          "ゲット！"
        );
      }
    },
    subtitle_first: function () {
      // サブタイトル1行目
      if (this.can_mix_device_type) {
        return "12時間ごとに10回まで遊べるよ！";
      } else {
        return "12時間ごとに5回まで遊べるよ！";
      }
    },
    number_of_plays: function () {
      // プレイ回数説明文
      if (this.can_mix_device_type) {
        return "12時間ごとに10回までプレイできます。(1回最大3ステージ)";
      } else {
        return "12時間ごとに5回までプレイできます。(1回最大3ステージ)<br/>パソコンとスマホそれぞれで5回遊べます。";
      }
    },
    exchange_medals_string: function () {
      // 交換数説明文
      if (this.exhange_medals_unit > 1) {
        // バッジ最大数が2以上の場合
        return (
          "バッジ" +
          this.exhange_medals_unit.toString() +
          "コで" +
          this.exchange_rate_to.toString() +
          this.unit_name +
          "に交換！"
        );
      } else {
        return "";
      }
    },
    auto_exchange_text: function () {
      // 自動交換数説明文(遊び方内)
      if (!this.is_auto_exchange) {
        // 自動交換なし
        return "";
      } else {
        // 自動交換あり
        return (
          this.exchange_rate_from +
          "点貯まると、" +
          this.exchange_rate_to +
          this.unit_name +
          "に自動交換されます。"
        );
      }
    },
    start_text: function () {
      if (this.can_play) {
        return "ゲームを始める";
      } else {
        return this.next_refresh_hour + "時まで遊べないよ！";
      }
    },
    rotationing_home_name: function () {
      if (this.$route.path.match("/top0")) {
        return "Home1";
      } else if (this.$route.path.match("/top1")) {
        return "Home2";
      } else if (this.$route.path.match("/top2")) {
        return "Home0";
      } else {
        return "Home0";
      }
    },
  },
  methods: {
    gotoPointHistories: function () {
      this.$router.push({
        name: "PointHistory",
      });
    },
    openExchange: function () {
      this.is_showing_exchange = true;
    },
    closeExchange: function () {
      this.is_showing_exchange = false;
    },
    exchanged: function () {
      this.is_showing_exchange = false;
      this.callGetApi();
    },
    setUser: function (info) {
      this.next_refresh_hour = info.data.next_switch_hour;
      this.refresh_hour_1 = info.data.switch_hour_1;
      this.refresh_hour_2 = info.data.switch_hour_2;
      this.can_mix_device_type = info.data.can_mix_device_type;
      this.is_auto_exchange = info.data.is_auto_exchange;
      this.delay_text = info.data.delay_text;
      this.exchange_rate_from = info.data.exchange_rate_from;
      this.exchange_rate_to = info.data.exchange_rate_to;
      this.exchange_unit = info.data.exchange_unit;
      this.is_first_play = info.data.is_first_play;
      this.life = info.data.life;
      this.point = info.data.point;
      this.exchanged_point = info.data.exchanged_point;
      this.playing_game_score = info.data.playing_game_score;
      this.site_name = info.data.site_name;
      this.unit_name = info.data.unit_name;
      setSiteName(this.site_name);
      setIsFirstPlay(this.is_first_play);
      setIsShowPopupImmediately(true); // TODO 暫定。以前pontaのみパッと出さないようにするために実装。いずれはパッと出す出さないサイトをDB管理する。
      setIsLoadAdWithStartGame(false); // TODO 暫定。スタート時のリロードを実施しない。
      setCanPlay(this.can_play);
      setPlayingGameScore(this.playing_game_score);
      setNeedsSingleRequest(true); // もともと楽天だけだったけど全サイト、シングルリクエストにした

      // cmsite
      if (this.site_name == "cmsite") {
        window.location.href = "https://cmsite.cmnw.jp/cm";
      }
      // rakuten
      if (this.site_name == "rakuten") {
        window.location.href = "https://pointmall.rakuten.co.jp/game/shinden";
      }
      // phalanx_saison
      if (this.site_name == "phalanx_saison") {
        let time_renewal = "2024-12-16 11:00:00";
        if (new Date().getTime() > new Date(time_renewal).getTime()) {
          window.location.href = "https://www.a-q-f.com/openpc/sugoroku/index.html?incd=header_gnav_sugoroku";
        }
      }

      this.is_ready = true;
    },
    callGetApi: function () {
      Api.getUser(this, this.setUser);
    },
    callbackSubmit: function () {
      this.callGetApi();
    },
    postScoreApi: function (value, stage, status) {
      Api.postScore(this, value, stage, status, this.callbackSubmit);
    },
  },
  watch: {
    site_name: function (newValue, oldValue) {

      setTimeout(() => {
        afterSetupUser();
      }, 0);
    },
    is_ready: function (newValue, oldValue) {
      setTimeout(() => {
        initScreen();
      }, 0);
    },
  },
  
  mounted: function () {
    this.callGetApi();

    initCallback((value, stage, status) => {
      this.postScoreApi(value, stage, status);
    });
  },
};
</script>

<style lang="scss" scoped>
.game_selectItem {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  @media screen and (max-width: 414px) {
    height: auto;
  }
  @media screen and (max-width: 320px) {
    height: auto;
  }
}

.exchange {
  width: 220px;
  margin: 0 auto 12px auto;
}

.history {
  width: 220px;
  margin: 0 auto 12px auto;
}

.info {
  line-height: 18px;
}
</style>
